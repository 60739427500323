<template>
	<div>
		<ContentHeader title="Setup Cabang" url="/setup-cabang" subTitle="Tambah Data Cabang" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Tembah Data Cabang</div></h3>

	                <div class="card-tools">
	                </div>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
	              		<form @submit.prevent="prosesCreateCabang">
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="kode_cabang">Kode Cabang <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="kode_cabang" name="kode_cabang" v-model="cabang.kode_cabang" autocomplete="off" required="">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="kode_wilayah">Kode Wilayah <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="kode_wilayah" name="kode_wilayah" v-model="cabang.kode_wilayah" autocomplete="off" required="">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nama">Nama Cabang <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="nama" name="nama" v-model="cabang.nama" required="" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="alamat">Alamat <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="alamat" name="alamat" v-model="cabang.alamat" autocomplete="off" required="">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="telepon">Telepon <span class="text-danger">*</span></label>
			                    <input type="tel" class="form-control" id="telepon" name="telepon" v-model="cabang.telepon" autocomplete="off" required="">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="sio">SIO (Surat Izin Operasi)</label>
			                    <input type="text" class="form-control" id="sio" name="sio" v-model="cabang.sio" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <label for="masa_awal_berlaku">Masa Berlaku SIO</label>
			                  <div class="row">
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="awal_berlaku_sio">Awal Berlaku SIO</span>
					                    <input type="date" class="form-control" id="awal_berlaku_sio" name="awal_berlaku_sio" v-model="cabang.awal_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="akhir_berlaku_sio">Akhir Berlaku SIO</span>
					                    <input type="date" class="form-control" id="akhir_berlaku_sio" name="akhir_berlaku_sio" v-model="cabang.akhir_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="npwp">NPWP</label>
			                    <input type="text" class="form-control" id="npwp" name="npwp" v-model="cabang.npwp" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nomor_handphone">Nomor Handphone</label>
			                    <input type="tel" class="form-control" id="nomor_handphone" name="nomor_handphone" v-model="cabang.nomor_handphone" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="penanggung_jawab">Penanggung Jawab</label>
			                    <input type="text" class="form-control" id="penanggung_jawab" name="penanggung_jawab" v-model="cabang.penanggung_jawab" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2">
			                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</button>
			                </div>
		                </form>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
            	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

import { computed, ref, reactive } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'

export default{
	components: {
		ContentHeader
	},
	setup(){
		const user = computed(() => store.getters['auth/user'])
		const loading = ref(false)

		const cabang = reactive({
			'cabang_id' : user.value.cabang_id,
			'jenis' : 'cabang',
			'kode_cabang' : '',
			'kode_wilayah' : '',
			'nama' : '',
			'sio' : '',
			'awal_berlaku_sio' : '',
			'akhir_berlaku_sio' : '',
			'npwp' : '',
			'alamat' : '',
			'telepon' : '',
			'nomor_handphone' : '',
			'penanggung_jawab' : ''
		})


		const prosesCreateCabang = () => {
			loading.value = true
			axios.post(`api/cabang/create`, cabang)
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil Membuat Data Cabang',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})
				loading.value = false
				router.push('/setup-cabang')
				console.log(response)
			})
			.catch((error) => {
				Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal menambahkan data',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})	
				loading.value = false
				console.log(error)
			})
		}

		return {
			cabang,
			prosesCreateCabang,
			loading
		}
	}
}
</script>